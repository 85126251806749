<template>
  <div class="background">
    <Services />
  </div>
</template>

<script>
import Services from "@/components/elements/dashboard/services/Services";

export default {
  name: "DocumentManagementServices",

  components: {
    Services,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
