<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="services"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="servicesPerPage"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Services</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template
                  v-if="$can('service_create')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Service
                  </v-btn>
                </template>
                <v-card :class="customClass">
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <!-- Name -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedService.name"
                              :rules="nameRules"
                              label="Name"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Name <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Cost Number Of Credits -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedService.cost_number_of_credits"
                              :rules="costNumberOfCreditsRules"
                              label="Cost Number Of Credits"
                              required
                              type="number"
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Cost Number Of Credits
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Description -->
                          <v-col class="mt-n5" cols="12">
                            <v-textarea
                              v-model="editedService.description"
                              :rules="descriptionRules"
                              label="Description"
                            >
                              <template v-slot:label>
                                <p class="body-1">Description</p>
                              </template>
                            </v-textarea>
                          </v-col>

                          <!-- Service Categories -->
                          <v-col
                            v-if="$can('super_admin')"
                            class="mt-n5"
                            cols="12"
                          >
                            <v-select
                              v-model="serviceCategoryName"
                              :items="serviceCategoryNamesList"
                              :rules="serviceCategoryNameRules"
                              attach
                              label="Service Categories"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Service Category
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-select>
                          </v-col>

                          <!-- Ative -->
                          <v-col class="mt-n5" cols="12">
                            <v-checkbox
                              v-model="editedService.active"
                              :label="`Active`"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="btn btn-primary" @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    Are you sure you want to delete this service?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="btn btn-primary" @click="deleteServiceConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <!-- active -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.active="{ item }">
            <v-chip
              v-if="item.active"
              class="ma-2"
              color="green"
              text-color="white"
            >
              Yes
            </v-chip>

            <v-chip v-else class="ma-2" color="red" text-color="white">
              No
            </v-chip>
          </template>

          <!-- created_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.created_at="{ item }">
            {{ $helpers.datetime(item.created_at) }}
          </template>

          <!-- updated_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.updated_at="{ item }">
            {{ $helpers.datetime(item.updated_at) }}
          </template>

          <!-- actions -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="$can('service_update')"
              small
              class="mr-2"
              @click="editService(item)"
              color="primary"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="$can('service_delete')"
              small
              @click="deleteService(item)"
              color="red"
            >
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>
        </v-data-table>

        <v-row class="text-center px-4 align-center mt-4" wrap>
          <v-col class="text-truncate" cols="12" md="2">
            Total {{ totalRecords }} records
          </v-col>

          <v-col cols="12" md="6">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </v-col>

          <v-col cols="6" md="3">
            <v-select
              v-model="perPageChoice"
              label="Services per page"
              item-text="text"
              item-value="value"
              :items="perPageChoices"
              dense
              outlined
              hide-details
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DocumentManagementServices",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Id", align: "start", value: "id" },
        { text: "Name", value: "name" },
        { text: "Cost Number Of Credits", value: "cost_number_of_credits" },
        { text: "Description", value: "description" },
        { text: "Service Category", value: "service_category_id" },
        { text: "Active", value: "active" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      services: [],

      serviceCategories: [],
      serviceCategoryName: "",
      serviceCategoryNamesList: [],

      editedIndex: -1,
      editedService: {
        id: 0,
        name: "",
        description: "",
        active: true,
        service_category_id: 0,
        created_at: "",
        updated_at: "",
      },
      defaultService: {
        id: 0,
        name: "",
        description: "",
        active: true,
        service_category_id: 0,
        created_at: "",
        updated_at: "",
      },

      // name
      nameRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length >= 3 || "Can not be less than 3 characters",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      // costNumberOfCredits
      costNumberOfCreditsRules: [(v) => !!v || "Required"],

      // description
      descriptionRules: [],

      // serviceCategoryName
      serviceCategoryNameRules: [(v) => !!v || "Required"],

      page: 1,
      servicesPerPage: 100,
      totalRecords: 0,
      pageCount: 0,
      perPageChoice: { text: "100 records/page", value: 100 },
      perPageChoices: [
        { text: "5 records/page", value: 5 },
        { text: "25 records/page", value: 25 },
        { text: "50 records/page", value: 50 },
        { text: "100 records/page", value: 100 },
        { text: "150 records/page", value: 150 },
        { text: "200 records/page", value: 200 },
      ],

      customClass: "",
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),

    // formTitle
    formTitle() {
      return this.editedIndex === -1 ? "New Service" : "Edit Service";
    },
  },

  watch: {
    // dialog
    dialog(val) {
      val || this.close();
    },

    // dialogDelete
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // page
    page() {
      this.getApiPagedServices();
    },

    // servicesPerPage
    servicesPerPage() {
      this.getApiPagedServices();
    },

    // perPageChoice
    perPageChoice(val) {
      this.servicesPerPage = val.value;
      this.page = 1;
    },
  },

  created() {
    if (!this.$can("service_access")) {
      this.$router.push({ name: "DashboardInbox" });
    }

    // customClass
    this.customClass = "padding-bottom";

    // this.getApiServices();
    this.getApiPagedServices();

    // getApiServiceCategories
    this.getApiServiceCategories();
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
    }),

    // validateForm
    validateForm() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateApiService();
        } else {
          this.createApiService();
        }
      }
    },

    // editService
    editService(service) {
      this.editedIndex = this.services.indexOf(service);
      this.editedService = Object.assign({}, service);
      this.dialog = true;

      if (this.editedIndex != -1) {
        this.serviceCategoryName = this.$helpers.getNameByIdFromList(
          this.serviceCategories,
          service.service_category_id
        );
      }
    },

    // deleteService
    deleteService(service) {
      this.editedIndex = this.services.indexOf(service);
      this.editedService = Object.assign({}, service);
      this.dialogDelete = true;
    },

    // deleteServiceConfirm
    deleteServiceConfirm() {
      this.deleteApiService();
    },

    // close
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedService = Object.assign({}, this.defaultService);
        this.editedIndex = -1;
        this.serviceCategoryName = "";
      });

      this.$refs.form.resetValidation();
    },

    // closeDelete
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedService = Object.assign({}, this.defaultService);
        this.editedIndex = -1;
      });
    },

    // save
    save() {
      this.validateForm();
    },

    // getApiServices
    async getApiServices() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("services");

      if (res.status == 200) {
        this.services = res.data;
      }

      this.overlay = false;
    },

    // getApiPagedServices
    async getApiPagedServices() {
      this.overlay = true;

      const res = await this.$helpers.getApiData(
        `services/paged?page_no=${this.page}&page_size=${this.servicesPerPage}`
      );

      if (res.status == 200) {
        this.services = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
      }

      this.overlay = false;
    },

    // createApiService
    async createApiService() {
      this.overlay = true;

      var reqData = {
        name: this.editedService.name,
        cost_number_of_credits: this.editedService.cost_number_of_credits,
        description: this.editedService.description,
        active: this.editedService.active,
      };

      if (this.serviceCategoryName != "") {
        var id = this.$helpers.getIdByNameFromList(
          this.serviceCategories,
          this.serviceCategoryName
        );

        if (id > 0) {
          reqData.service_category_id = id;
        }
      }

      const res = await this.$helpers.createApiData(
        "services",
        reqData,
        "Service Created Successfully!"
      );

      if (res.status == 200) {
        this.services.push(res.data);

        this.close();
      }

      this.overlay = false;
    },

    // updateApiService
    async updateApiService() {
      this.overlay = true;

      var reqData = {
        id: this.editedService.id,
        name: this.editedService.name,
        cost_number_of_credits: this.editedService.cost_number_of_credits,
        description: this.editedService.description,
        active: this.editedService.active,
      };

      if (this.serviceCategoryName != "") {
        var id = this.$helpers.getIdByNameFromList(
          this.serviceCategories,
          this.serviceCategoryName
        );

        if (id > 0) {
          reqData.service_category_id = id;
        }
      }

      const res = await this.$helpers.updateApiData(
        `services/${this.editedService.id}`,
        reqData,
        "Service Updated Successfully!"
      );

      if (res.status == 200) {
        Object.assign(this.services[this.editedIndex], res.data);

        this.close();
      }

      this.overlay = false;
    },

    // deleteApiService
    async deleteApiService() {
      this.overlay = true;

      const res = await this.$helpers.deleteApiData(
        `services/${this.editedService.id}`,
        "Service Deleted Successfully!"
      );

      if (res.status == 200) {
        this.services.splice(this.editedIndex, 1);

        this.closeDelete();
      }

      this.overlay = false;
    },

    // Get Api Service Categories
    async getApiServiceCategories() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("service-categories");

      if (res.status == 200) {
        this.serviceCategories = res.data;

        this.serviceCategoryNamesList = this.$helpers.getNamesFromList(
          this.serviceCategories
        );
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.padding-bottom {
  padding-bottom: 160px;
}
</style>
